import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

interface OrderDetail {
  total_fees: number;
  status: string;
  id: number;
  bakerName: string;
  date: string;
  pickup: string;
  images: string[];

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push: any };
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bakers: OrderDetail[];
  filteredBakers:OrderDetail[];
  searchQuery: string,
  page: number,
  cartItems: any[],
  itemsPerPage: number,
  costSummary: {
    products: number,
    tips: number,
    fee: number,
  },
  selectedValue:string,
  open:boolean

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserOrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderHistoryApiCallId: any = "";
  getOrderDetailsApiCallId: any = "";
  id = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedValue: 'creationDateNewest',
      open: false,
      searchQuery: "",
      bakers: [],
      filteredBakers:[],
      page: 1,
      itemsPerPage: 2,
      cartItems: [],
      costSummary: {
        products: 0,
        tips: 0,
        fee: 0
      }

    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    super.componentDidMount();
    const id = this.props.location.pathname.match(/\/userOrderDetails\/(\d+)/);
    const orderId = id ? id[1] : null;
    if (orderId == null) {
      this.getOrderHisory(this.state.selectedValue,this.state.page, this.state.itemsPerPage);
    } else {
      this.getOrderDetails(orderId)
    }
    this.updateFilteredBakers();
    
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getOrderHistoryApiCallId) {
      const orderDetails = responseJson.data.map((order: any) => ({
        id: order.id,
        bakerName: order.attributes.customer.data.attributes.first_name,
        date: order.attributes.date,
        pickup: order.attributes.pick_up,
        status: order.attributes.status,
        images: order.attributes.order_items.data[0].attributes.catalogue.data.attributes.images,
        total_fees: order.attributes.total_fees
        

      }));

      this.setState({
        bakers: orderDetails,
        filteredBakers: orderDetails
        
      });
     
    } 

    if (apiRequestCallId === this.getOrderDetailsApiCallId) {
      const cartItems = responseJson.data.map((item: any) => ({
        id: item.id,
        imageUrl: item.attributes.catalogue.data.attributes.images || '',
        name: item.attributes.catalogue.data.attributes.name,
        description: item.attributes.catalogue.data.attributes.description,
        price: item.attributes.price,
        discountedPrice: item.attributes.taxable_value
      }));
      const products = cartItems.reduce((total: any, item: any) => total + item.price, 0);
      const tips = 1.80;
      const fee = 2.00;

      this.setState({ 
          cartItems, 
          costSummary: { products, tips, fee }
      });
      this.setState({ cartItems: cartItems });
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleSearchChange = (event:any) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });

    const filteredBakers = this.state.bakers.filter((baker) =>
      baker.bakerName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    this.setState({ filteredBakers });
  };


  handlePaginationChange = (event: any, value: any) => {
    console.log("Page changed to:", value)
    this.setState({ page: value }, () => {
      this.updateFilteredBakers();
    });
  

  };

  getOrderHisory = (assending:any,page = 1, itemsPerPage = 2) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderHistoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderHistory}??sort_by=${assending}&page=${page}&limit=${itemsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getOrderDetails = (id: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetails}?order_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  gotoCartItems = (id: any) => {
    this.props.history.push(`/userOrderDetails/${id}`);
  }
  handleChange = (event: any) => {
    this.setState({ selectedValue: event.target?.value });
    if (this.state.selectedValue === 'nameAsc') {
      this.setState((prevState) => ({
        filteredBakers: prevState.bakers.slice().sort((a, b) => a.bakerName.localeCompare(b.bakerName)),
      }));
    } else if (this.state.selectedValue === 'nameDesc') {
      this.setState((prevState) => ({
        filteredBakers: prevState.bakers.slice().sort((a, b) => b.bakerName.localeCompare(a.bakerName)),
      }));
    }
    else {
      this.getOrderHisory(event.target?.value)
    }

  };

  toggleMenu = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };
 
  updateFilteredBakers = () => {
    const { bakers, page, itemsPerPage } = this.state;
    const startIndex = (page - 1) * itemsPerPage;
    const paginatedData = bakers.slice(startIndex, startIndex + itemsPerPage);
    this.setState({ filteredBakers: paginatedData })
  };
  // Customizable Area End
}