// App.js - WEB
import React, { Component } from 'react';
// Customizable Area 
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { Link } from 'react-router-dom';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import CatalogueCreate from "../../blocks/catalogue/src/CatalogueCreate.web";
import CatalogueEdit from "../../blocks/catalogue/src/CatalogueEdit.web";
import CatalogueDetails from "../../blocks/catalogue/src/CatalogueDetails.web";
import UserOrderHistory from "../../blocks/ordermanagement/src/UserOrderHistory.web";
import userOrderDetails from "../../blocks/ordermanagement/src/UserOrderDetails.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Location from "../../blocks/location/src/Location";
import AddressLocation from "../../blocks/location/src/Location.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationUser from "../../blocks/email-account-registration/src/EmailAccountRegistrationUser.web";
import Login from "../../blocks/email-account-login/src/Login";
import SignupLoginDesktop from "../../blocks/email-account-login/src/SignupLoginDesktop.web";
import RolesLogin from "../../blocks/email-account-login/src/RolesLogin.web";
import RolesSignup from "../../blocks/email-account-login/src/RolesSignup.web";
import ShoppingCart from "../../blocks/shoppingcart/src/ShoppingCartOrders.web";
import UserAddressLocation from "../../blocks/location/src/UserLocation.web";
import ActivateAccount from "../../blocks/email-account-registration/src/ActivateAccount.web";
import SuccessAccount from "../../blocks/email-account-registration/src/SuccessAcount.web";
import EnterCode from "../../blocks/email-account-registration/src/EnterCode.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Payments from "../../blocks/stripepayments/src/Payments.web";
import StripePayments from "../../blocks/stripepayments/src/UserPayments.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings from "../../blocks/settings2/src/Settings.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Share from "../../blocks/share/src/Share";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import Legal from "../../blocks/settings2/src/Legal.web"
import ForgotPassOtp from '../../blocks/forgot-password/src/ForgotPassOtp.web';
import OtpSentSuccessfully from '../../blocks/forgot-password/src/OtpSentSuccessfully.web';
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web"
// import { Router, Route, Route } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Typography } from "@mui/material";
import { PrivateRoute } from './PrivateRoute';
import OrderManagementView from '../../blocks/ordermanagement/src/OrderManagementView';

// Customizable Area End
// Customizable Area Start
const routeMap = {
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountLoginBlock: {
    component: Login,
    path: "/EmailAccountLoginBlock"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Notifications: {
    component: Notifications,
    path: "/notifications"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  Search: {
    component: Search,
    path: "/Search"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  // StripePayments:{
  //  component:StripePayments,
  // path:"/StripePayments"},
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Settings: {
    component: Settings,
    path: "/Settings"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Legal: {
    component: Legal,
    path: "/Legal"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  ForgotPassOtp: {
    component: ForgotPassOtp,
    path: "/ForgotPassOtp"
  },
  OtpSentSuccessfully: {
    component: OtpSentSuccessfully,
    path: "/OtpSentSuccessfully"
  },

  Home: {
    component: Login,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const headerStyle = {
  backgroundColor: "#292524",
  height: "79px",
  padding: "16px 32px 16px 32px",
  borderRadius: "8px 8px 8px 8px",
  margin: "0px 25px 25px 0px",
  transition: 'margin-left 0.3s ease',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#ffffff"
};

const routingStyle = {
  ...headerStyle,
  height: "calc(100vh - 190px)",
};

const containerStyle = {
  display: 'flex',
  height: '100vh',
  backgroundColor: "#000000"
};


const contentStyle = {
  flex: '1 1 auto',
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#292521"
          },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#292521"
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C2B30A"
          },
        }
      }
    }
  }
});
// Customizable Area End
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBakeryOpen: false,
      user: null,
    };
    this.openbackry = this.openbackry.bind(this)
    // Bind the toggle method to 'this'
  }
  openbackry(value) {
    this.setState({ isBakeryOpen: value })
  }

  render() {
    // Customizable Area Start
    const { isBakeryOpen } = this.state;
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const user = localStorage.getItem('user');
    const useData = JSON.parse(user);
    const userObj = useData?.data?.attributes;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={SignupLoginDesktop} />
          <Route exact path="/rolesLogin" component={RolesLogin} />
          <Route exact path="/activateAccount" component={ActivateAccount} />
          <Route exact path="/successAccount" component={SuccessAccount} />
          <Route exact path="/otp" component={EnterCode} />
          <Route exact path="/rolesSignup" component={RolesSignup} />
          <Route exact path="/Legal2" component={Legal} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/userOrderHistory" component={UserOrderHistory} />
          <Route exact path="/userOrderDetails/:id" component={userOrderDetails} />
          <Route exact path="/registration" component={EmailAccountRegistration} />
          <Route exact path="/userRegistration" component={EmailAccountRegistrationUser} />
          <Route exact path="/shoppingcart" component={ShoppingCart} />
          <Route exact path="/UserAddressLocation" component={UserAddressLocation} />
          <Route exact path="/AddressLocation" component={AddressLocation} />
          <Route exact path="/ForgotPassOtp" component={ForgotPassOtp} />
          <Route exact path="/OtpSentSuccessfully" component={OtpSentSuccessfully} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route exact path="/share" component={Share} />
          <Route exact path="/StripePayments" component={StripePayments} />

          <Route exact path="/CustomisableUserProfiles" component={CustomisableUserProfiles} />
          <Route>
            <div className="container" style={containerStyle}>
              <HomeScreen openbackry={this.openbackry} />
              <div className="content" style={contentStyle}>
                <div style={headerStyle}>
                  <Typography variant='subtitle1' style={{
                    color: "#C2B30A", fontWeight: "700", textTransform: "uppercase",
                    fontSize: "16px",
                    textAlign: "left"
                  }}>DASHBOARD</Typography>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "50px" }}>
                    <div>
                      <img src={require("../public/notifications.svg")} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
                      <img src={require("../public/user.png")} />
                      <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                        <Typography variant='subtitle2' style={{
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '17.78px',
                          textAlign: 'left'
                        }}>{userObj?.first_name || ''} {userObj?.last_name || ''}</Typography>
                        <Typography variant='body2'>{userObj?.email || ''}</Typography>
                      </div>
                    </div>
                    <div style={{ gap: '4px', width: '132px', borderRadius: '50px', padding: '8px', border: `1px solid ${isBakeryOpen ? '#009168' : '#F59E0B'}`, display: 'flex', justifyContent: "space-around", alignItems: "center", width: "145px" }}>
                      <img src={isBakeryOpen ? require("../public/tick-green.svg") : require("../public/cross-red.svg")} alt="status" style={{ height: '16px', width: '16px' }} />
                      <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, lineHeight: '20.32px', textAlign: 'left' }}>
                        {isBakeryOpen ? "Bakery Open" : "Bakery Close"}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div style={routingStyle}>
                  <Switch>
                    <PrivateRoute
                      path="/dashboard"
                      component={() => <Dashboard openbackry={this.openbackry} />}
                    />                  <PrivateRoute path="/catalogue" exact component={Catalogue} />
                    <PrivateRoute path="/order-history" component={UserOrderHistory} />
                    <PrivateRoute path="/payments" component={Payments} />
                    <PrivateRoute path="/notifications" component={Notifications} />
                    <PrivateRoute path="/summary" component={Search} />
                    <PrivateRoute path="/Legal" component={Legal} />
                    <PrivateRoute path="/settings" component={Settings} />
                    {/* <PrivateRoute path="/support" component={"support"} /> */}
                    <PrivateRoute path="/catalogue/catalogue-create" component={CatalogueCreate} />
                    <PrivateRoute path="/catalogue/catalogue-edit" component={CatalogueEdit} />
                    <PrivateRoute path="/catalogue/details" component={CatalogueDetails} />
                    {/* <Route path="*" component={()=><div style={{ border: "1px solid white" }}>Not found PAge</div>} /> */}
                  </Switch>
                </div>
              </div>
            </div>
          </Route>
          <Route path="*" component={<div style={{ border: "1px solid white" }}>Not found PAge</div>} />
        </Switch>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

export default App;